body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: MontRegular;
  src: url(/static/media/Mont-Regular.3e8472dc.woff) format("woff");
}

@font-face {
  font-family: MontSemiBold;
  src: url(/static/media/Mont-SemiBold.76ffaeaa.woff) format("woff");
}

@font-face {
  font-family: MontBold;
  src: url(/static/media/Mont-Bold.207b8966.woff) format("woff");
}

@media only screen and (max-width: 823px) {
  #main-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }
  #main-container #animated-container {
    background: #2d51a3;
    z-index: 999999;
  }
  #main-container #fixed-container {
    display: none;
  }
  #main-container #logo-container {
    position: absolute;
    width: 40%;
    z-index: 999999;
    left: 30%;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  #main-container #logo-container #logo {
    max-width: 100%;
  }
  #main-container #upper-title {
    font-family: MontSemiBold;
    position: absolute;
    top: -40px;
    left: 60px;
    font-size: 1.1em;
  }
  #main-container #social-logos {
    position: absolute;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    width: 20px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    justify-content: center;
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }
  #main-container #social-logos .logo {
    width: 14px;
    height: 30px;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
  #main-container #social-logos .logo:hover {
    fill: #2d51a3 !important;
  }
  #main-container #social-logos .logo:hover path {
    fill: #2d51a3 !important;
  }
  #main-container #menu-btn {
    position: absolute;
    width: 80px;
    top: 49%;
    left: -85px;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  #main-container #menu-btn .lines {
    display: inline-block;
    height: 20px;
  }
  #main-container #menu-btn .lines .line {
    height: 2px;
    background: black;
    margin-bottom: 3px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #main-container #menu-btn .lines .l1 {
    width: 20px;
    margin-left: 10px;
  }
  #main-container #menu-btn .lines .l2 {
    width: 30px;
  }
  #main-container #menu-btn .lines .l3 {
    width: 15px;
    margin-left: 15px;
  }
  #main-container #menu-btn .menutext {
    display: inline-block;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  #main-container #menu-btn .menutext small {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-family: MontBold;
    font-size: 12px;
  }
  #main-container #menu-btn .closetext {
    display: none;
  }
  #main-container #menu-btn .menutext,
  #main-container #menu-btn .closetext {
    margin-bottom: 4px;
  }
  #main-container #menu-btn:hover .lines .l1 {
    width: 28px;
  }
  #main-container #menu-btn:hover .lines .l2 {
    width: 38px;
  }
  #main-container #menu-btn:hover .lines .l3 {
    width: 26px;
    margin-left: 12px;
  }
  #main-container #menu-btn:hover .menutext small,
  #main-container #menu-btn:hover .closetext small {
    letter-spacing: 2px;
  }
  #main-container #subcontent {
    position: absolute;
    top: 25%;
    left: -250px;
    z-index: 999999;
    height: 60%;
    display: flex;
    flex-direction: column;
  }
  #main-container #subcontent .pinfo .name {
    font-family: MontBold;
    margin-bottom: 2px;
    font-size: medium;
  }
  #main-container #subcontent .pinfo .pos {
    font-size: smaller;
  }
  #main-container #subcontent .pinfo .mnumber {
    margin: 0px;
  }
  #main-container #subcontent .pinfo p {
    font-size: smaller;
    margin: 0px;
    font-family: MontRegular;
  }
  #main-container #subcontent ul {
    flex-grow: 1;
  }
  #main-container #subcontent ul li {
    list-style: none;
    font-family: MontRegular;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer;
    font-size: 13px;
  }
  #main-container #subcontent ul li h2 {
    margin-bottom: 0px;
    font-size: 15px;
  }
  #main-container #subcontent ul li .underline {
    height: 2px;
    width: 0px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  #main-container #subcontent ul li .ul1 {
    background: #34495e !important;
  }
  #main-container #subcontent ul li .ul2 {
    background: #f1c40f !important;
  }
  #main-container #subcontent ul li .ul3 {
    background: #27ae60 !important;
  }
  #main-container #subcontent ul li:hover .underline {
    width: 100% !important;
  }
  #main-container #subcontent h1 {
    font-family: MontBold;
    text-decoration: underline;
    margin-bottom: 2px;
    font-size: 17px;
  }
  #main-container #subcontent p {
    font-family: MontRegular;
    color: #333;
  }
  #main-container #subcontent a {
    color: #333;
    text-decoration: none;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #main-container #subcontent a:hover {
    color: #2d51a3;
    font-weight: bold;
  }
  .menuOn #animated-container {
    z-index: 999999 !important;
    width: 25% !important;
    margin-left: 75% !important;
    margin-right: 0px;
  }
  .menuOn #logo-container {
    width: 25% !important;
    right: 0 !important;
    left: unset !important;
  }
  .menuOn #menu-btn .lines .l3 {
    display: none !important;
  }
  .menuOn #menu-btn .lines .l1 {
    width: 30px !important;
    margin-left: 0px !important;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .menuOn #menu-btn .lines .l2 {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: absolute;
    top: 0px;
  }
  .menuOn #menu-btn .menutext {
    display: none !important;
  }
  .menuOn #menu-btn .closetext {
    display: inline-block !important;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .menuOn #menu-btn .closetext small {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-family: MontBold;
    font-size: 12px;
  }
  .menuOn #menu-btn:hover .lines .l1 {
    width: 30px !important;
  }
  .menuOn #menu-btn:hover .lines .l2 {
    width: 30px !important;
  }
  .menuOn #menu-btn:hover .closetext small {
    letter-spacing: 2px;
  }
  .animateZoomedIn {
    -webkit-animation-name: zommedInMobile;
            animation-name: zommedInMobile;
    -webkit-animation-duration: .9s;
            animation-duration: .9s;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
            animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .doneLoading {
    height: calc(100% - 120px);
    width: 40%;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: 30%;
    margin-right: 30%;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
}

@media only screen and (max-width: 1022px) and (min-width: 824px) {
  #main-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }
  #main-container #animated-container {
    background: #2d51a3;
    z-index: 999;
  }
  #main-container #fixed-container {
    height: calc(100% - 120px);
    width: calc(50% - 60px);
    background: white;
    position: absolute;
    right: 60px;
  }
  #main-container #logo-container {
    position: absolute;
    width: 50%;
    z-index: 999999;
    left: 25%;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  #main-container #logo-container #logo {
    max-width: 100%;
  }
  #main-container #upper-title {
    font-family: MontSemiBold;
    position: absolute;
    top: -40px;
    left: 60px;
  }
  #main-container #social-logos {
    position: absolute;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    width: 20px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    justify-content: center;
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }
  #main-container #social-logos .logo {
    width: 14px;
    height: 30px;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #main-container #social-logos .logo:hover {
    fill: #2d51a3 !important;
  }
  #main-container #social-logos .logo:hover path {
    fill: #2d51a3 !important;
  }
  #main-container #menu-btn {
    position: absolute;
    width: 80px;
    top: 49%;
    left: -85px;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  #main-container #menu-btn .lines {
    display: inline-block;
    height: 20px;
  }
  #main-container #menu-btn .lines .line {
    height: 2px;
    background: black;
    margin-bottom: 3px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #main-container #menu-btn .lines .l1 {
    width: 20px;
    margin-left: 10px;
  }
  #main-container #menu-btn .lines .l2 {
    width: 30px;
  }
  #main-container #menu-btn .lines .l3 {
    width: 15px;
    margin-left: 15px;
  }
  #main-container #menu-btn .menutext {
    display: inline-block;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  #main-container #menu-btn .menutext small {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-family: MontBold;
    font-size: 12px;
  }
  #main-container #menu-btn .closetext {
    display: none;
  }
  #main-container #menu-btn .menutext,
  #main-container #menu-btn .closetext {
    margin-bottom: 4px;
  }
  #main-container #menu-btn:hover .lines .l1 {
    width: 28px;
  }
  #main-container #menu-btn:hover .lines .l2 {
    width: 38px;
  }
  #main-container #menu-btn:hover .lines .l3 {
    width: 26px;
    margin-left: 12px;
  }
  #main-container #menu-btn:hover .menutext small,
  #main-container #menu-btn:hover .closetext small {
    letter-spacing: 2px;
  }
  #main-container #subcontent {
    position: absolute;
    top: 25%;
    left: -270px;
    height: 60%;
    display: flex;
    flex-direction: column;
  }
  #main-container #subcontent .pinfo .name {
    font-family: MontBold;
    margin-bottom: 2px;
    font-size: medium;
  }
  #main-container #subcontent .pinfo .pos {
    font-size: smaller;
  }
  #main-container #subcontent .pinfo .mnumber {
    margin: 0px;
  }
  #main-container #subcontent .pinfo p {
    font-size: smaller;
    margin: 0px;
    font-family: MontRegular;
  }
  #main-container #subcontent ul {
    flex-grow: 1;
  }
  #main-container #subcontent ul li {
    list-style: none;
    font-family: MontRegular;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  #main-container #subcontent ul li h2 {
    margin-bottom: 0px;
  }
  #main-container #subcontent ul li .underline {
    height: 3px;
    width: 0px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  #main-container #subcontent ul li .ul1 {
    background: #34495e !important;
  }
  #main-container #subcontent ul li .ul2 {
    background: #f1c40f !important;
  }
  #main-container #subcontent ul li .ul3 {
    background: #27ae60 !important;
  }
  #main-container #subcontent ul li:hover .underline {
    width: 100% !important;
  }
  #main-container #subcontent h1 {
    font-family: MontBold;
    text-decoration: underline;
    margin-bottom: 2px;
  }
  #main-container #subcontent p {
    font-family: MontRegular;
    color: #333;
  }
  #main-container #subcontent a {
    color: #333;
    text-decoration: none;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #main-container #subcontent a:hover {
    color: #2d51a3;
    font-weight: bold;
  }
  .animateZoomedIn {
    -webkit-animation-name: zommedIn;
            animation-name: zommedIn;
    -webkit-animation-duration: .9s;
            animation-duration: .9s;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
            animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .doneLoading {
    height: calc(100% - 120px);
    width: calc(50% - 60px);
    margin: 60px 0px 60px 60px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  .menuOn #animated-container {
    margin-left: 40%;
  }
  .menuOn #logo-container {
    width: 10% !important;
    left: 40% !important;
  }
  .menuOn #menu-btn .lines .l3 {
    display: none !important;
  }
  .menuOn #menu-btn .lines .l1 {
    width: 30px !important;
    margin-left: 0px !important;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .menuOn #menu-btn .lines .l2 {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: absolute;
    top: 0px;
  }
  .menuOn #menu-btn .menutext {
    display: none !important;
  }
  .menuOn #menu-btn .closetext {
    display: inline-block !important;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .menuOn #menu-btn .closetext small {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-family: MontBold;
    font-size: 12px;
  }
  .menuOn #menu-btn:hover .lines .l1 {
    width: 30px !important;
  }
  .menuOn #menu-btn:hover .lines .l2 {
    width: 30px !important;
  }
  .menuOn #menu-btn:hover .closetext small {
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1023px) {
  #main-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }
  #main-container #animated-container {
    background: #2d51a3;
    z-index: 999;
  }
  #main-container #fixed-container {
    height: calc(100% - 120px);
    width: calc(50% - 60px);
    background: white;
    position: absolute;
    right: 60px;
  }
  #main-container #logo-container {
    position: absolute;
    width: 50%;
    z-index: 999999;
    left: 25%;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  #main-container #logo-container #logo {
    max-width: 100%;
  }
  #main-container #upper-title {
    font-family: MontSemiBold;
    position: absolute;
    top: -40px;
    left: 60px;
  }
  #main-container #social-logos {
    position: absolute;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    width: 20px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    justify-content: center;
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }
  #main-container #social-logos .logo {
    width: 14px;
    height: 30px;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #main-container #social-logos .logo:hover {
    fill: #2d51a3 !important;
  }
  #main-container #social-logos .logo:hover path {
    fill: #2d51a3 !important;
  }
  #main-container #menu-btn {
    position: absolute;
    width: 80px;
    top: 49%;
    left: -85px;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  #main-container #menu-btn .lines {
    display: inline-block;
    height: 20px;
  }
  #main-container #menu-btn .lines .line {
    height: 2px;
    background: black;
    margin-bottom: 3px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #main-container #menu-btn .lines .l1 {
    width: 20px;
    margin-left: 10px;
  }
  #main-container #menu-btn .lines .l2 {
    width: 30px;
  }
  #main-container #menu-btn .lines .l3 {
    width: 15px;
    margin-left: 15px;
  }
  #main-container #menu-btn .menutext {
    display: inline-block;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  #main-container #menu-btn .menutext small {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-family: MontBold;
    font-size: 12px;
  }
  #main-container #menu-btn .closetext {
    display: none;
  }
  #main-container #menu-btn .menutext,
  #main-container #menu-btn .closetext {
    margin-bottom: 4px;
  }
  #main-container #menu-btn:hover .lines .l1 {
    width: 28px;
  }
  #main-container #menu-btn:hover .lines .l2 {
    width: 38px;
  }
  #main-container #menu-btn:hover .lines .l3 {
    width: 26px;
    margin-left: 12px;
  }
  #main-container #menu-btn:hover .menutext small,
  #main-container #menu-btn:hover .closetext small {
    letter-spacing: 2px;
  }
  #main-container #subcontent {
    position: absolute;
    top: 25%;
    left: -270px;
    height: 60%;
    display: flex;
    flex-direction: column;
  }
  #main-container #subcontent .pinfo .name {
    font-family: MontBold;
    margin-bottom: 2px;
    font-size: medium;
  }
  #main-container #subcontent .pinfo .pos {
    font-size: smaller;
  }
  #main-container #subcontent .pinfo .mnumber {
    margin: 0px;
  }
  #main-container #subcontent .pinfo p {
    font-size: smaller;
    margin: 0px;
    font-family: MontRegular;
  }
  #main-container #subcontent ul {
    flex-grow: 1;
  }
  #main-container #subcontent ul li {
    list-style: none;
    font-family: MontRegular;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  #main-container #subcontent ul li h2 {
    margin-bottom: 0px;
  }
  #main-container #subcontent ul li .underline {
    height: 3px;
    width: 0px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  #main-container #subcontent ul li .ul1 {
    background: #34495e !important;
  }
  #main-container #subcontent ul li .ul2 {
    background: #f1c40f !important;
  }
  #main-container #subcontent ul li .ul3 {
    background: #27ae60 !important;
  }
  #main-container #subcontent ul li:hover .underline {
    width: 100% !important;
  }
  #main-container #subcontent h1 {
    font-family: MontBold;
    text-decoration: underline;
    margin-bottom: 2px;
  }
  #main-container #subcontent p {
    font-family: MontRegular;
    color: #333;
  }
  #main-container #subcontent a {
    color: #333;
    text-decoration: none;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #main-container #subcontent a:hover {
    color: #2d51a3;
    font-weight: bold;
  }
  .animateZoomedIn {
    -webkit-animation-name: zommedIn;
            animation-name: zommedIn;
    -webkit-animation-duration: .9s;
            animation-duration: .9s;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
            animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .doneLoading {
    height: calc(100% - 120px);
    width: calc(50% - 60px);
    margin: 60px 0px 60px 60px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  .menuOn #animated-container {
    margin-left: 40%;
  }
  .menuOn #logo-container {
    width: 10% !important;
    left: 40% !important;
  }
  .menuOn #menu-btn .lines .l3 {
    display: none !important;
  }
  .menuOn #menu-btn .lines .l1 {
    width: 30px !important;
    margin-left: 0px !important;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .menuOn #menu-btn .lines .l2 {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: absolute;
    top: 0px;
  }
  .menuOn #menu-btn .menutext {
    display: none !important;
  }
  .menuOn #menu-btn .closetext {
    display: inline-block !important;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .menuOn #menu-btn .closetext small {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-family: MontBold;
    font-size: 12px;
  }
  .menuOn #menu-btn:hover .lines .l1 {
    width: 30px !important;
  }
  .menuOn #menu-btn:hover .lines .l2 {
    width: 30px !important;
  }
  .menuOn #menu-btn:hover .closetext small {
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 1440px) {
  #main-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }
  #main-container #animated-container {
    background: #2d51a3;
    z-index: 999;
  }
  #main-container #fixed-container {
    height: calc(100% - 120px);
    width: calc(50% - 60px);
    background: white;
    position: absolute;
    right: 60px;
  }
  #main-container #logo-container {
    position: absolute;
    width: 50%;
    z-index: 999999;
    left: 25%;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  #main-container #logo-container #logo {
    max-width: 100%;
  }
  #main-container #upper-title {
    font-family: MontSemiBold;
    position: absolute;
    top: -40px;
    left: 60px;
  }
  #main-container #social-logos {
    position: absolute;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    width: 20px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    justify-content: center;
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }
  #main-container #social-logos .logo {
    width: 14px;
    height: 30px;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #main-container #social-logos .logo:hover svg {
    fill: #2d51a3 !important;
  }
  #main-container #social-logos .logo:hover path {
    fill: #2d51a3 !important;
  }
  #main-container #menu-btn {
    position: absolute;
    width: 80px;
    top: 49%;
    left: -85px;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  #main-container #menu-btn .lines {
    display: inline-block;
    height: 20px;
  }
  #main-container #menu-btn .lines .line {
    height: 2px;
    background: black;
    margin-bottom: 3px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #main-container #menu-btn .lines .l1 {
    width: 20px;
    margin-left: 10px;
  }
  #main-container #menu-btn .lines .l2 {
    width: 30px;
  }
  #main-container #menu-btn .lines .l3 {
    width: 15px;
    margin-left: 15px;
  }
  #main-container #menu-btn .menutext {
    display: inline-block;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  #main-container #menu-btn .menutext small {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-family: MontBold;
    font-size: 12px;
  }
  #main-container #menu-btn .closetext {
    display: none;
  }
  #main-container #menu-btn .menutext,
  #main-container #menu-btn .closetext {
    margin-bottom: 4px;
  }
  #main-container #menu-btn:hover .lines .l1 {
    width: 28px;
  }
  #main-container #menu-btn:hover .lines .l2 {
    width: 38px;
  }
  #main-container #menu-btn:hover .lines .l3 {
    width: 26px;
    margin-left: 12px;
  }
  #main-container #menu-btn:hover .menutext small,
  #main-container #menu-btn:hover .closetext small {
    letter-spacing: 2px;
  }
  .animateZoomedIn {
    -webkit-animation-name: zommedIn;
            animation-name: zommedIn;
    -webkit-animation-duration: .9s;
            animation-duration: .9s;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
            animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .doneLoading {
    height: calc(100% - 120px);
    width: calc(50% - 60px);
    margin: 60px 0px 60px 60px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  .menuOn #animated-container {
    margin-left: 40%;
  }
  .menuOn #logo-container {
    width: 10% !important;
    left: 40% !important;
  }
  .menuOn #menu-btn .lines .l3 {
    display: none !important;
  }
  .menuOn #menu-btn .lines .l1 {
    width: 30px !important;
    margin-left: 0px !important;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .menuOn #menu-btn .lines .l2 {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: absolute;
    top: 0px;
  }
  .menuOn #menu-btn .menutext {
    display: none !important;
  }
  .menuOn #menu-btn .closetext {
    display: inline-block !important;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .menuOn #menu-btn .closetext small {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-family: MontBold;
    font-size: 12px;
  }
  .menuOn #menu-btn:hover .lines .l1 {
    width: 30px !important;
  }
  .menuOn #menu-btn:hover .lines .l2 {
    width: 30px !important;
  }
  .menuOn #menu-btn:hover .closetext small {
    letter-spacing: 2px;
  }
}

.medZIndex {
  z-index: 99999;
}

.animated-container-mleft {
  margin-left: 120px;
}

.animateTranslateIn {
  -webkit-animation-name: translateIn;
          animation-name: translateIn;
  -webkit-animation-duration: .9s;
          animation-duration: .9s;
  -webkit-animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
          animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.loadTitle {
  -webkit-animation-name: loadTitleIn;
          animation-name: loadTitleIn;
  -webkit-animation-duration: .9s;
          animation-duration: .9s;
  -webkit-animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
          animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.loadMenuButton {
  -webkit-animation-name: loadMenuButton;
          animation-name: loadMenuButton;
  -webkit-animation-duration: .9s;
          animation-duration: .9s;
  -webkit-animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
          animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.fadeMeOut {
  -webkit-animation: fadeout 0.5s ease 0s 1 normal forwards;
          animation: fadeout 0.5s ease 0s 1 normal forwards;
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes zommedInMobile {
  0% {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
  }
  50% {
    height: calc(100% - 120px);
    width: calc(100% - 120px);
    margin: 60px;
  }
  100% {
    height: calc(100% - 120px);
    width: 40%;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: 30%;
    margin-right: 30%;
  }
}

@keyframes zommedInMobile {
  0% {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
  }
  50% {
    height: calc(100% - 120px);
    width: calc(100% - 120px);
    margin: 60px;
  }
  100% {
    height: calc(100% - 120px);
    width: 40%;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: 30%;
    margin-right: 30%;
  }
}

@-webkit-keyframes zommedIn {
  0% {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
  }
  50% {
    height: calc(100% - 120px);
    width: calc(100% - 120px);
    margin: 60px;
  }
  100% {
    height: calc(100% - 120px);
    width: calc(50% - 60px);
    margin: 60px 0px 60px 60px;
  }
}

@keyframes zommedIn {
  0% {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
  }
  50% {
    height: calc(100% - 120px);
    width: calc(100% - 120px);
    margin: 60px;
  }
  100% {
    height: calc(100% - 120px);
    width: calc(50% - 60px);
    margin: 60px 0px 60px 60px;
  }
}

@-webkit-keyframes translateIn {
  0% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes translateIn {
  0% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes loadTitleIn {
  0% {
    top: -40px;
  }
  100% {
    top: 10px;
  }
}

@keyframes loadTitleIn {
  0% {
    top: -40px;
  }
  100% {
    top: 10px;
  }
}

@-webkit-keyframes loadMenuButton {
  0% {
    left: -85px;
  }
  100% {
    left: 35px;
  }
}

@keyframes loadMenuButton {
  0% {
    left: -85px;
  }
  100% {
    left: 35px;
  }
}
/*# sourceMappingURL=App.css.map */
@media only screen and (max-width: 823px) {
  #subcontent {
    position: absolute;
    top: 25%;
    z-index: 999999;
    height: 60%;
    display: flex;
    flex-direction: column;
  }
  #subcontent .pinfo .name {
    font-family: MontBold;
    margin-bottom: 2px;
    font-size: medium;
  }
  #subcontent .pinfo .pos {
    font-size: smaller;
  }
  #subcontent .pinfo .mnumber {
    margin: 0px;
  }
  #subcontent .pinfo p {
    font-size: smaller;
    margin: 0px;
    font-family: MontRegular;
  }
  #subcontent ul {
    flex-grow: 1;
  }
  #subcontent ul li {
    list-style: none;
    font-family: MontRegular;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer;
    font-size: 13px;
  }
  #subcontent ul li h2 {
    margin-bottom: 0px;
    font-size: 15px;
  }
  #subcontent ul li .underline {
    height: 2px;
    width: 0px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  #subcontent ul li .ul1 {
    background: #34495e !important;
  }
  #subcontent ul li .ul2 {
    background: #f1c40f !important;
  }
  #subcontent ul li .ul3 {
    background: #27ae60 !important;
  }
  #subcontent ul li:hover .underline {
    width: 100% !important;
  }
  #subcontent h1 {
    font-family: MontBold;
    text-decoration: underline;
    margin-bottom: 2px;
    font-size: 17px;
  }
  #subcontent p {
    font-family: MontRegular;
    color: #333;
  }
  #subcontent a {
    color: #333;
    text-decoration: none;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #subcontent a:hover {
    color: #2d51a3;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1022px) and (min-width: 824px) {
  #subcontent {
    position: absolute;
    top: 25%;
    height: 60%;
    display: flex;
    flex-direction: column;
  }
  #subcontent .pinfo .name {
    font-family: MontBold;
    margin-bottom: 2px;
    font-size: medium;
  }
  #subcontent .pinfo .pos {
    font-size: smaller;
  }
  #subcontent .pinfo .mnumber {
    margin: 0px;
  }
  #subcontent .pinfo p {
    font-size: smaller;
    margin: 0px;
    font-family: MontRegular;
  }
  #subcontent ul {
    flex-grow: 1;
  }
  #subcontent ul li {
    list-style: none;
    font-family: MontRegular;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  #subcontent ul li h2 {
    margin-bottom: 0px;
  }
  #subcontent ul li .underline {
    height: 3px;
    width: 0px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  #subcontent ul li .ul1 {
    background: #34495e !important;
  }
  #subcontent ul li .ul2 {
    background: #f1c40f !important;
  }
  #subcontent ul li .ul3 {
    background: #27ae60 !important;
  }
  #subcontent ul li:hover .underline {
    width: 100% !important;
  }
  #subcontent h1 {
    font-family: MontBold;
    text-decoration: underline;
    margin-bottom: 2px;
  }
  #subcontent p {
    font-family: MontRegular;
    color: #333;
  }
  #subcontent a {
    color: #333;
    text-decoration: none;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #subcontent a:hover {
    color: #2d51a3;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1023px) {
  #subcontent {
    position: absolute;
    top: 25%;
    height: 60%;
    display: flex;
    flex-direction: column;
  }
  #subcontent .pinfo .name {
    font-family: MontBold;
    margin-bottom: 2px;
    font-size: medium;
  }
  #subcontent .pinfo .pos {
    font-size: smaller;
  }
  #subcontent .pinfo .mnumber {
    margin: 0px;
  }
  #subcontent .pinfo p {
    font-size: smaller;
    margin: 0px;
    font-family: MontRegular;
  }
  #subcontent ul {
    flex-grow: 1;
  }
  #subcontent ul li {
    list-style: none;
    font-family: MontRegular;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  #subcontent ul li h2 {
    margin-bottom: 0px;
  }
  #subcontent ul li .underline {
    height: 3px;
    width: 0px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  #subcontent ul li .ul1 {
    background: #34495e !important;
  }
  #subcontent ul li .ul2 {
    background: #f1c40f !important;
  }
  #subcontent ul li .ul3 {
    background: #27ae60 !important;
  }
  #subcontent ul li:hover .underline {
    width: 100% !important;
  }
  #subcontent h1 {
    font-family: MontBold;
    text-decoration: underline;
    margin-bottom: 2px;
  }
  #subcontent p {
    font-family: MontRegular;
    color: #333;
  }
  #subcontent a {
    color: #333;
    text-decoration: none;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #subcontent a:hover {
    color: #2d51a3;
    font-weight: bold;
  }
}

@media only screen and (min-width: 1440px) {
  #subcontent {
    position: absolute;
    top: 25%;
    height: 60%;
    display: flex;
    flex-direction: column;
  }
  #subcontent .pinfo .name {
    font-family: MontBold;
    margin-bottom: 2px;
    font-size: medium;
  }
  #subcontent .pinfo .pos {
    font-size: smaller;
  }
  #subcontent .pinfo .mnumber {
    margin: 0px;
  }
  #subcontent .pinfo p {
    font-size: smaller;
    margin: 0px;
    font-family: MontRegular;
  }
  #subcontent ul {
    flex-grow: 1;
  }
  #subcontent ul li {
    list-style: none;
    font-family: MontRegular;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    position: relative;
    cursor: pointer;
  }
  #subcontent ul li h2 {
    margin-bottom: 0px;
  }
  #subcontent ul li .underline {
    height: 3px;
    width: 0px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    position: absolute;
    top: 15%;
    height: 70%;
    opacity: .3;
  }
  #subcontent ul li .ul1 {
    background: #34495e !important;
  }
  #subcontent ul li .ul2 {
    background: #f1c40f !important;
  }
  #subcontent ul li .ul3 {
    background: #27ae60 !important;
  }
  #subcontent ul li:hover .underline {
    width: 100% !important;
  }
  #subcontent h1 {
    font-family: MontBold;
    text-decoration: underline;
    margin-bottom: 2px;
  }
  #subcontent p {
    font-family: MontRegular;
    color: #333;
  }
  #subcontent a {
    color: #333;
    text-decoration: none;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
  #subcontent a:hover {
    color: #2d51a3;
    font-weight: bold;
  }
}
/*# sourceMappingURL=menu.css.map */
@media only screen and (max-width: 823px) {
  #about {
    position: absolute;
    width: 100%;
    top: 60px;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
    padding-left: 115px;
    padding-right: 100px;
    box-sizing: border-box;
    overflow: auto;
    height: calc(100% - 60px);
  }
  #about .title {
    font-family: MontBold;
    font-size: 34px;
    margin-bottom: 0px;
  }
  #about .subtitle {
    font-family: MontSemiBold;
    margin-top: 0px;
    font-size: 24px;
    opacity: .8;
  }
  #about .maintext {
    font-family: MontRegular;
    font-size: 20px;
  }
  #about .stack {
    font-family: MontRegular;
    font-size: 18px;
    color: #333;
    margin-bottom: 0px;
  }
  #about .technologies {
    margin-top: 0px;
  }
  #about .technologies li {
    list-style: none;
    font-family: MontRegular;
  }
}

@media only screen and (max-width: 1022px) and (min-width: 824px) {
  #about {
    position: absolute;
    right: 70px;
    top: 10%;
    width: 40%;
    height: 80%;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #about .title {
    font-family: MontBold;
    font-size: 34px;
    margin-bottom: 0px;
  }
  #about .subtitle {
    font-family: MontSemiBold;
    margin-top: 0px;
    font-size: 24px;
    opacity: .8;
  }
  #about .maintext {
    font-family: MontRegular;
    font-size: 20px;
  }
  #about .stack {
    font-family: MontRegular;
    font-size: 18px;
    color: #333;
    margin-bottom: 0px;
  }
  #about .technologies {
    margin-top: 0px;
  }
  #about .technologies li {
    list-style: none;
    font-family: MontRegular;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1023px) {
  #about {
    position: absolute;
    right: 70px;
    top: 10%;
    width: 40%;
    height: 80%;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #about .title {
    font-family: MontBold;
    font-size: 34px;
    margin-bottom: 0px;
  }
  #about .subtitle {
    font-family: MontSemiBold;
    margin-top: 0px;
    font-size: 24px;
    opacity: .8;
  }
  #about .maintext {
    font-family: MontRegular;
    font-size: 20px;
  }
  #about .stack {
    font-family: MontRegular;
    font-size: 18px;
    color: #333;
    margin-bottom: 0px;
  }
  #about .technologies {
    margin-top: 0px;
  }
  #about .technologies li {
    list-style: none;
    font-family: MontRegular;
  }
}

@media only screen and (min-width: 1440px) {
  #about {
    position: absolute;
    right: 70px;
    top: 10%;
    width: 40%;
    height: 80%;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #about .title {
    font-family: MontBold;
    font-size: 34px;
    margin-bottom: 0px;
  }
  #about .subtitle {
    font-family: MontSemiBold;
    margin-top: 0px;
    font-size: 24px;
    opacity: .8;
  }
  #about .maintext {
    font-family: MontRegular;
    font-size: 20px;
  }
  #about .stack {
    font-family: MontRegular;
    font-size: 18px;
    color: #333;
    margin-bottom: 0px;
  }
  #about .technologies {
    margin-top: 0px;
  }
  #about .technologies li {
    list-style: none;
    font-family: MontRegular;
  }
}
/*# sourceMappingURL=about.css.map */
@media only screen and (max-width: 823px) {
  #work {
    position: absolute;
    width: 100%;
    top: 60px;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
    padding-left: 103px;
    padding-right: 100px;
    box-sizing: border-box;
    overflow: auto;
    height: calc(100% - 60px);
  }
  #work .title {
    font-family: MontSemiBold;
    font-size: 18px;
  }
  #work .section {
    margin-bottom: 25px;
  }
  #work .section .header .title {
    margin: 0px;
    font-size: 16px;
  }
  #work .section .header .subtitle {
    margin: 0px;
    font-family: MontRegular;
  }
  #work .section .body {
    font-family: Montserrat;
    font-size: 15px;
  }
  #work .footer {
    font-family: MontSemiBold;
  }
  #work a {
    text-decoration: none;
    color: #3f51b5;
  }
}

@media only screen and (max-width: 1022px) and (min-width: 824px) {
  #work {
    position: absolute;
    right: 70px;
    top: 10%;
    width: 40%;
    height: 80%;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #work .title {
    font-family: MontSemiBold;
    font-size: 18px;
  }
  #work .section {
    margin-bottom: 25px;
  }
  #work .section .header .title {
    margin: 0px;
    font-size: 16px;
  }
  #work .section .header .subtitle {
    margin: 0px;
    font-family: MontRegular;
  }
  #work .section .body {
    font-family: Montserrat;
    font-size: 15px;
  }
  #work .footer {
    font-family: MontSemiBold;
  }
  #work a {
    text-decoration: none;
    color: #3f51b5;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1023px) {
  #work {
    position: absolute;
    right: 70px;
    top: 10%;
    width: 40%;
    height: 80%;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #work .title {
    font-family: MontSemiBold;
    font-size: 18px;
  }
  #work .section {
    margin-bottom: 25px;
  }
  #work .section .header .title {
    margin: 0px;
    font-size: 16px;
  }
  #work .section .header .subtitle {
    margin: 0px;
    font-family: MontRegular;
  }
  #work .section .body {
    font-family: Montserrat;
    font-size: 15px;
  }
  #work .footer {
    font-family: MontSemiBold;
  }
  #work a {
    text-decoration: none;
    color: #3f51b5;
  }
}

@media only screen and (min-width: 1440px) {
  #work {
    position: absolute;
    right: 70px;
    top: 10%;
    width: 40%;
    height: 80%;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #work .title {
    font-family: MontSemiBold;
    font-size: 18px;
  }
  #work .section {
    margin-bottom: 25px;
  }
  #work .section .header .title {
    margin: 0px;
    font-size: 16px;
  }
  #work .section .header .subtitle {
    margin: 0px;
    font-family: MontRegular;
  }
  #work .section .body {
    font-family: Montserrat;
    font-size: 15px;
  }
  #work .footer {
    font-family: MontSemiBold;
  }
  #work a {
    text-decoration: none;
    color: #3f51b5;
  }
}
/*# sourceMappingURL=work.css.map */
@media only screen and (max-width: 823px) {
  #contact {
    position: absolute;
    width: 100%;
    top: 60px;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
    padding-left: 103px;
    padding-right: 100px;
    box-sizing: border-box;
    overflow: auto;
    height: calc(100% - 60px);
  }
  #contact .title {
    font-family: MontSemiBold;
    font-size: 18px;
  }
  #contact ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
  #contact ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    font-family: MontRegular;
    font-size: 14px;
  }
  #contact ul li .icon {
    margin-right: 15px;
  }
  #contact ul li .icon svg {
    height: 20px;
    width: 20px;
  }
  #contact a {
    text-decoration: none;
    color: #3f51b5;
  }
}

@media only screen and (max-width: 1022px) and (min-width: 824px) {
  #contact {
    position: absolute;
    right: 70px;
    top: 10%;
    width: 40%;
    height: 80%;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #contact .title {
    font-family: MontSemiBold;
    font-size: 22px;
  }
  #contact ul {
    list-style: none;
  }
  #contact ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    font-family: MontRegular;
    font-size: 18px;
  }
  #contact ul li .icon {
    margin-right: 20px;
  }
  #contact ul li .icon svg {
    height: 25px;
    width: 25px;
  }
  #contact a {
    text-decoration: none;
    color: #3f51b5;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1023px) {
  #contact {
    position: absolute;
    right: 70px;
    top: 10%;
    width: 40%;
    height: 80%;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #contact .title {
    font-family: MontSemiBold;
    font-size: 22px;
  }
  #contact ul {
    list-style: none;
  }
  #contact ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    font-family: MontRegular;
    font-size: 18px;
  }
  #contact ul li .icon {
    margin-right: 20px;
  }
  #contact ul li .icon svg {
    height: 25px;
    width: 25px;
  }
  #contact a {
    text-decoration: none;
    color: #3f51b5;
  }
}

@media only screen and (min-width: 1440px) {
  #contact {
    position: absolute;
    right: 70px;
    top: 10%;
    width: 40%;
    height: 80%;
    z-index: 999999;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #contact .title {
    font-family: MontSemiBold;
    font-size: 22px;
  }
  #contact ul {
    list-style: none;
  }
  #contact ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    font-family: MontRegular;
    font-size: 18px;
  }
  #contact ul li .icon {
    margin-right: 20px;
  }
  #contact ul li .icon svg {
    height: 25px;
    width: 25px;
  }
  #contact a {
    text-decoration: none;
    color: #3f51b5;
  }
}
/*# sourceMappingURL=contact.css.map */
